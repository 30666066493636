import theme from './theme.js'

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

const homeStyles = makeStyles(() => ({
    container: {
      maxWidth: 1440,
      margin: '3% auto',
      [theme.breakpoints.down('lg')]: {
        margin: '3%',
      },
      [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
          }
    },
    midContainer: {
      padding: "3% 0"
    },
    header: {
      padding: "0 0 5% 0",
  
    },
    symbolImage: {
      height: '70px',
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        //fontSize: '5rem'
      },
    },
    spaceContent: {
      lineHeight: 2
    },
    horizontalList: {
      display: 'flex'
    },
    box: {
      backgroundColor: theme.palette.background.veryDark
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    column: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        alignItems: 'center'
      }
    },
    icon: {
      marginRight: '10px',
      cursor: 'pointer',
      '&:hover':{
        fill: theme.palette.primary.main,

      }
    },
    vertDist: {
      display: 'grid',
      gridGap: '20px',
      padding: '30px 0'
    },
    gridCont: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 40%)',
      gridGap: '50px 10%',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'auto',
        gridGap: '20px'
      }
    },
    level1: {
      gridRowEnd: 'span 3'
    },
    level2:{
      gridRowEnd: 'span 2'
    },
    fullWidth:{
      width: '100%'
    }
  }))

  export default homeStyles