import firebase from 'firebase'

const firebaseConfig =  {
    apiKey: "AIzaSyAVSGk-3fs7LwC6MEgELs2MkB8D-3cotPA",
    authDomain: "cronatr-5c05b.firebaseapp.com",
    databaseURL: "https://cronatr-5c05b.firebaseio.com",
    projectId: "cronatr-5c05b",
    storageBucket: "cronatr-5c05b.appspot.com",
    messagingSenderId: "568322856926",
    appId: "1:568322856926:web:41ed0e2eb408bb27672f4f",
    measurementId: "G-TWKSK70LXX"
};

firebase.initializeApp(firebaseConfig)

export default firebase