
import React, { useState, useRef, useEffect } from 'react'
import { Collapse, Fade, Grow, Slide, Zoom } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
const variantList = [
  { name: "zoom", comp: Zoom },
  { name: "collapse", comp: Collapse },
  { name: "fade", comp: Fade },
  { name: "grow", comp: Grow },
  { name: "slide", comp: Slide },
]

const useStyles = makeStyles({})

const OnViewAnim = ({ children, variant, timeout, transitionDelay, direction }) => {
  const classes = useStyles()
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    //return () => observer.unobserve(domRef.current);  // clean up
  }, []);

  const Anim = (variantList.find(v => v.name === variant) || variantList[0]).comp

  return (
    <div className={`${classes.box}`} ref={domRef}>
      <Anim appear={true} 
      timeout={timeout} 
      direction={direction||'right'} 
      style={{ transitionDelay }} 
      in={isVisible}>
        {children}
      </Anim>
    </div>
  );
};

export default OnViewAnim