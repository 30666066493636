import { ReactComponent as BoxIcon } from '../icons/cubeBox.svg'
import { ReactComponent as RoboticsIcon } from '../icons/robotics.svg'
import DesktopMacIcon from '@material-ui/icons/DesktopMac';

const specs = [
    { title: 'FrontEnd Developer', icon: DesktopMacIcon, qty: 10, accent: true },
    { title: 'Robotics Developer', icon: RoboticsIcon, qty: 4, },
    { title: 'Product Designer', icon: BoxIcon, qty: 3, },
]

const selectedWorks = [
    {
        title: 'Fylo landing', spec: 'FrontEnd', img: 'https://github.com/altovoy/fylo-dark-theme-landing-page-master/raw/master/design/desktop-preview.jpg',
        description: `Responsive landing page of a fictitious data storage service FrontEnd Mentor Challenge. 
    The dark theme and multiple images make that a pretty web page`,
        tecnologies: 'Pure HTML & CSS'
    },
    {
        title: 'Patacoré', spec: 'FrontEnd', img: './images/PatacorePreview.jpg',
        description: `SPA with mobile and desktop support to make restaurant orders and registry historical data for improved  the service. 
        The real time database make better the communication between place areas.`,
        tecnologies: `ReactJS, MaterialUI, Firebase, Mobx, ChartJS`
    },
    {
        title: 'Exedra', spec: 'FrontEnd', img: './images/ExedraPreview.jpg',
        description: `It is a web tool to support the information management of robotics tournaments. The software
    is being designed with the aim of allowing tournament organizers to manage data in real time
    that facilitate understanding the evolution of the event over time and keep competitors up to date
    and viewers.`,
        tecnologies: 'ReactJS, Firebase, OBS Studio'

    },

]


export { specs, selectedWorks }