import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { amber, blue } from '@material-ui/core/colors';

let theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#FFCD1C',
            dark: '#b28f13',
            light: '#ffd749'
        },
        secondary: blue,
        background: {
            default: "#333334",
            veryDark: "#232324",
            light: '#5b5b5c'
        }
    },
    typography: {
        h1: {
            fontWeight: 600,
        },
        subtitle1: {
            letterSpacing: '4px',
            color: '#707382',

        }
    }
});

theme = responsiveFontSizes(theme)
export default theme;