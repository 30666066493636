import React, { useState } from 'react'
import clsx from 'clsx'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Typography } from '@material-ui/core';

import MaximizeIcon from '@material-ui/icons/Maximize';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import homeStyles from './styles/home.js'

import theme from './styles/theme'
import OnViewAnim from './components/OnViewAnim.jsx'
import Testimonials from './components/Testimonials.jsx'
import { Box, Grid, List, ListItem, ListItemText, Link, Card, SvgIcon, TextField, IconButton } from '@material-ui/core'

import { ReactComponent as BoxIcon } from './icons/cubeBox.svg'
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'


import SpecifCard from './components/SpecifCard.jsx'
import WorkCard from './components/WorkCard.jsx'
import { specs, selectedWorks } from './dummyData'

import { storage, generateContactDocument } from './services'


function App() {
  const classes = homeStyles()
  const navTo = (e, pagelink) => {
    e.preventDefault()
    window.location.target = '_blank'
    window.location.href = pagelink
  }

  const [snack, setSnack] = useState({ open: false, severity: 'success', duration: 2000, message: 'Success !' })
  const closeSnack = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({ ...snack, open: false });
  };

  const openSnack = (message, severity, duration) => {
    message = message || 'Success'
    severity = severity || 'success'
    duration = duration || 2000
    setSnack({ ...snack, open: true, severity, message, duration })
  }

  const [formData, setFormData] = useState({ name: '', email: '', attachment: '', file: '' })

  const onFormChange = e => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleAttachClick = e => {
    var file = e.target.files[0]
    if (!file) { return }
    var thisRef = storage.ref(`/${'attachDocuments'}/${file.name}`);
    thisRef.put(file).on('state_changed',
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot)
      }, (err) => {
        //catches the errors
        console.log(err)
      }, () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        thisRef.getDownloadURL()
          .then(firebaseUrl => {
            setFormData({ ...formData, attachment: firebaseUrl, file: file.name })
            openSnack('File upload success!', 'success', 3000)
          })
      })
  }

  const handleSendContactClick = e => {
    e.preventDefault()
    generateContactDocument(formData).then(value => {
      value == 'success' ? openSnack('Request sended!', 'success', 3000) : openSnack('Error sending request, please try again', 'error', 3000)
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Snackbar open={snack.open} autoHideDuration={snack.duration} onClose={closeSnack}>
        <Alert onClose={closeSnack} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
      <Box className={classes.container}>

        <Grid
          className={classes.header}
          xs={12}
          container
          justify="space-between">
          <img className={classes.symbolImage} src="./images/yellow-symbol.png" alt="ATR yellow icon" />
          <List className={classes.horizontalList}>
            {/*<ListItem button>
              <ListItemText primary="Services" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Works" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Blog" />
            </ListItem>*/}
          </List>

        </Grid>

        <Grid container justify="space-between" sm={12} spacing={2} >

          <Grid item className={clsx(classes.column, classes.vertDist)} direction="column" container md={6} xs={12} >
            <div>
              <Typography
                className={classes.title}
                color="inherit"
                variant="h1">Alejandro Torres.</Typography>
              <MaximizeIcon color='primary' style={{ fontSize: 110 }} />
            </div>


            <div>
              <LinkedInIcon className={classes.icon} onClick={e => navTo(e, "https://www.linkedin.com/in/altovoy/")} />
              <GitHubIcon className={classes.icon} onClick={e => navTo(e, 'https://github.com/altovoy')} />
              <FacebookIcon className={classes.icon} onClick={e => navTo(e, 'https://www.facebook.com/ATRobotica')} />
            </div>


          </Grid>

          <Grid
            className={classes.vertDist}
            item
            container
            justify="space-around"
            direction="column" md={5} xs={12} >
            <Typography variant="subtitle1" component="p">- Introduction</Typography>
            <Typography variant="h3" component="h1">
              FrontEnd Web Developer and Industrial Automation Engineer</Typography>
            <Typography className={classes.spaceContent} variant="body2" component="p">
              Skills in web and mobile programming, as well as experience in electronics and robotics projects.
            <br />Good ability to solve problems in an innovative and effective way.
            <br />Excellent adaptability and learning of various areas, tools or development methodologies.
              </Typography>
            {/*<Link variant="subtitle2" color='primary' component="a">My history</Link>*/}
          </Grid>
        </Grid>


      </Box>

      <Box className={classes.box}>
        <Box className={clsx(classes.container, classes.midContainer)}>
          <Grid container sm={12} direction='row' justify="space-between">

            <Grid className={classes.vertDist} item container md={5} sm={5} xs={12}>

              <Typography className={classes.fullWidth} variant="h4" component="p">
                Let's create something together
              </Typography>
              <Typography className={classes.fullWidth}>
                Write me to start great projects!
              </Typography>

              <div className={classes.fullWidth}>

                <Typography variant="subtitle2" color='primary' href='https://api.whatsapp.com/send/?phone=573059206592' component="a" >
                  +57 3059206592
                  <br />
                </Typography>
                <Typography variant="subtitle2" color='primary' component="a"
                  href="
                https://mail.google.com/mail/?view=cm&fs=1&to=atroboticacol@gmail.com&su=Hello Alejandro! Let's create something together&body=I see your web page and need to start a new project" >
                  atroboticacol@gmail.com
              </Typography>
              </div>
            </Grid>


            <Grid className={classes.vertDist} md={5} sm={6} xs={12} direction="column" spacing={10} >
              <Typography variant="h4" component="p">
                You have the universe key
              </Typography>
              <Typography className={classes.spaceContent} variant="body1" commponent="p" >
                My purpose is to generate value for your projects
              </Typography>
              <Grid container xs={12}>
                <Grid item container sm={5} xs={6} alignItems="baseline">
                  <Typography variant="h2" component='p' color="primary" >
                    2
                   </Typography>
                  <Typography variant="subtitle2" component='p' style={{ marginLeft: '4%' }}>
                    Years of experience
                    </Typography>
                </Grid>
                <Grid item container sm={5} xs={6} alignItems="baseline" >
                  <Typography variant="h2" color="primary" >
                    17
                   </Typography>
                  <Typography variant="subtitle2" style={{ marginLeft: '4%' }}>
                    Satisfied
                    Clients
                    </Typography>
                </Grid>
              </Grid>

            </Grid>

          </Grid>

          <Grid container xs={12} spacing={2} justify="center" style={{ margin: '0 auto -150px auto' }}>
            {
              specs.map((spec, index) =>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12} >
                  <SpecifCard accent={spec.accent} icon={spec.icon} title={spec.title} qty={spec.qty} />
                </Grid>
              )
            }
          </Grid>

        </Box>
      </Box>

      <Box className={clsx(classes.container, classes.gridCont)} style={{ marginTop: '150px' }} >
        <div className={clsx(classes.level2, classes.vertDist)}  >
          <Typography variant="subtitle1" component='p' >
            - Portfolio
            </Typography  >

          <Typography variant='h4' component='p'>
            All Creative Works, Selected projects.
            </Typography>

          <Typography variant='body1' component='p'>
            All Creative Works, Selected projects.
            </Typography>

          { /*<Typography color='primary' variant='h6' component='a' href="#">
            Explore more
          </Typography>*/ }
        </div>

        {
          selectedWorks.map(work => {
            const { title, spec, img, description, tecnologies } = work

            return <div className={clsx(classes.box, classes.level1)}>
              <WorkCard title={title} spec={spec} img={img}
                description={description}
                tecnologies={tecnologies} /> </div>
          })
        }


      </Box>
      {
        /*
        <Box className={classes.box}>
      <Box className={classes.container} style={{ padding: '5% 0' }} >
        <Testimonials />
      </Box>
    </Box>
        */

      }


      <footer className={classes.box} style={{ padding: '5% 0 2% 0' }}>
        <Box className={clsx(classes.container)}>
          <Grid xs={12} container justify="space-between" spacing={2} >
            <Grid item sm={5} xs={12} >
              <Typography variant='h4' component='p'>
                Got a project?
              <br />
              Let's talk.

        </Typography>
              <br />
              <Typography component='p' >
                Write me to start great projects!
              </Typography>
              <br />
              <div>

                <Typography variant="subtitle2" color='primary' href='https://api.whatsapp.com/send/?phone=573059206592' component="a">
                  +57 3059206592
                <br />
                </Typography>
                <Typography variant="subtitle2" color='primary' component="a"
                  href="
              https://mail.google.com/mail/?view=cm&fs=1&to=atroboticacol@gmail.com&su=Hello Alejandro! Let's create something together&body=I see your web page and need to start a new project"
                >
                  atrobotica@gmail.com
              </Typography>
              </div>


            </Grid>

            <Grid item sm={6} xs={12}>
              <Typography variant='h4' component='p'>
                Estimate your project?
              <br />
              Let me know here.
            </Typography>
              <br />
              <br />
              <Typography variant='h5' component='p'>
                What's your name?
            </Typography>
              <TextField fullWidth name='name' onChange={onFormChange} />

              <Typography variant='h5' component='p'>
                <br />
              Your fancy email

            </Typography>
              <TextField fullWidth name='email' onChange={onFormChange} />
              <br />
              <Grid container xs={12} justify="space-between" style={{ marginTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography variant='h5' component='p'>
                    Tell me about your project
            </Typography>
                </Grid>
                <Grid item container justify='flex-end' alignItems='center' xs={6}>
                  <input
                    className={classes.input}
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleAttachClick}
                  />
                  <label htmlFor="contained-button-file">
                    <IconButton component='span'>
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                  <IconButton onClick={handleSendContactClick}>
                    <ArrowForwardIcon color='primary' />
                  </IconButton>

                </Grid>


              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography variant='body2' component='p'>
                  {formData.file}
                </Typography>
              </Grid>

            </Grid>
          </Grid>

          <div style={{ textAlign: 'center', padding: '5% 0' }}>
            <img className={classes.symbolImage} src="./images/yellow-symbol.png" alt="ATR yellow icon" />

            <Typography variant='body2' component='p'>
              <br />
              <b>Thanks for scrolling,</b> that't all folks.
          </Typography>
            <br />
            <br />
            <div>
              <LinkedInIcon className={classes.icon} onClick={e => navTo(e, "https://www.linkedin.com/in/altovoy/")} />
              <GitHubIcon className={classes.icon} onClick={e => navTo(e, 'https://github.com/altovoy')} />
              <FacebookIcon className={classes.icon} onClick={e => navTo(e, 'https://www.facebook.com/ATRobotica')} />
            </div>
          </div>

        </Box>
      </footer>

    </ThemeProvider >
  );
}

export default App;
