import Firebase from './Firebase';
const db = Firebase.firestore();
const auth = Firebase.auth();
const storage = Firebase.storage();

const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = db.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        const { email, displayName, photoURL } = user;
        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
};

const generateContactDocument = async(contact) => {
    try {
        const {name, email, attachment} = contact
        const date = new Date()
        await contactsCollection.doc(`${name} ${date}`).set({
            name, email, attachment, date
        })
        return 'success'
    }catch(error) {
        console.error("Error adding contact", error)
        return 'error'
    }
}

const contactsCollection = db.collection('contacts');



export {
    generateUserDocument,
    generateContactDocument,
    auth,
    db,
    storage
}