import theme from '../styles/theme.js'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, SvgIcon, Icon } from '@material-ui/core'
import { ReactComponent as BoxIcon } from '../icons/cubeBox.svg'

const useStyles = makeStyles(() => ({
    box: {
        
    },
    accent: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        
    },
    standard: {
        backgroundColor: theme.palette.background.light,
        
    },
    stdIcon: {
        fill: 'white'
    },
    icon:{
        margin: "13px 3%"
    },
    accIcon: {
        fill: theme.palette.background.default
    },
    title: {
        [theme.breakpoints.down('md')]:{

        }
    },
}))

const SpecifCard = (({accent, icon, title, qty}) => {
    const classes = useStyles()
    return (
        <div className={clsx(classes.box, accent?classes.accent:classes.standard)} style={{ padding: '5px', height: '300px', minHeight: '100%'  }}>
            <div style={{ height: '50%' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon 
                className={clsx(classes.icon, accent?classes.accIcon:classes.stdIcon)}
                fontSize="large"  
                component={icon} 
                />
                <div>
                    <Typography className={classes.title} variant="h3" component="p">
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        {qty+" projects"}
                    </Typography>
                    
                </div>
            </div>
        </div>
    )
})

export default SpecifCard