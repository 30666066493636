import React, { useState, useEffect } from 'react'
import theme from '../styles/theme'
import clsx from 'clsx'
import { Typography, Icon, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as QuotesIcon } from '../icons/quotes.svg'

const useStyles = makeStyles({
    root: {
        padding: '',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
          }
    },
    opaqueIcon: {
        fill: "#424456",
        fontSize: 80
    },
    itemButton: {
        height: '10px',
        background: theme.palette.action.disabled,
        marginLeft: '10px',
        "&:hover":{
            background: theme.palette.action.disabled
        }
    },
    primary: {
        background: theme.palette.primary.main
    },
    img: {
        width: '40%',
        height: '100%',
        margin: "0 5%",
        objectFit: 'contain'
    },
    vertDist: {
        display: 'grid',
        gridGap: '30px'
    }
})


const test = [
    {
        name: 'Jared Warn', job: 'CEO of BeSe',
        opinion: 'Amazing work! Very professional and beautifull, the best forever',
        img: "https://www.careeraddict.com/uploads/article/59099/illustration-man-suit.jpg"
    },
    {
        name: 'Jared Warner', job: 'CEO of BeServer',
        opinion: 'Amazing work! Very professional and beautifull, the best forever',
        img: "https://www.careeraddict.com/uploads/article/59099/illustration-man-suit.jpg"

    },
    {
        name: 'Jared', job: 'CEO of BeServer',
        opinion: 'Amazing work! Very professional and beautifull, the best forever',
        img: "https://www.careeraddict.com/uploads/article/59099/illustration-man-suit.jpg"
    },
    {
        name: 'Jared Wa', job: 'CEO of BeServer',
        opinion: 'Amazing work! Very professional and beautifull, the best forever',
        img: "https://www.careeraddict.com/uploads/article/59099/illustration-man-suit.jpg"
    }
]

const ItemSelector = ({ items, indexSelected, onSelect }) => {
    const classes = useStyles()

    const select = (e, item, index) => {
        e.preventDefault()
        onSelect && onSelect(item, index)
    }

    return (
        <div >
            {
                items && items.map((item, index) =>
                    <Button
                        contained
                        className={clsx(classes.itemButton,
                            { [classes.primary]: (indexSelected == index) })}
                        onClick={e => { select(e, item, index) }}
                    />
                )
            }
        </div>
    )
}


function Testimonials({ data }) {
    const classes = useStyles()
    const [selectedItem, setSelectedItem] = useState(test[0])
    const [indexSelected, setIndexSelected] = useState(0)
    data = data || test
    const onSelect = (item, index) => {
        setSelectedItem(item)
        setIndexSelected(index)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            const ind = indexSelected < test.length - 1 ? indexSelected + 1 : 0;
            onSelect(data[ind], ind)
        }, 3000);
        return () => clearTimeout(timer);
    });

    return (
        <div className={classes.root}>
            <img className={classes.img}
                src={selectedItem.img}
                alt="" />

            <div className={classes.vertDist} >
                <div>
                    <Icon className={classes.opaqueIcon} component={QuotesIcon} />
                    <Typography variant='h4'>
                        {selectedItem.opinion}
                    </Typography>
                </div>

                <div>
                    <Typography variant='h6' component='p'>{selectedItem.name}</Typography>
                    <Typography variant='body1' component='p'>{selectedItem.job}</Typography>
                </div>

                <ItemSelector onSelect={onSelect} indexSelected={indexSelected} items={data} />

            </div>
        </div>
    )
}

export default Testimonials