
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const useStyles = makeStyles({
    root: {
        position: 'relative',
        height: '100%', width: '100%',
        overflow: 'hidden',
        textAlign:'center',
        '&:hover': {
            "& $moreInfo": {
                display: 'flex'
            }
        },
        
    },
    moreContainer: {
        position: 'relative',
        height: '70%', width: '100%', paddingTop: '5%'
    },
    moreInfo: {
        display: 'none',
        position: 'absolute',
        background: 'rgba(10,10 ,10, 0.95)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        padding: '10% 5%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

function WorkCard({ title, spec, img, description, tecnologies }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container
                xs={12}
                direction='row'
                justify="center"
                alignItems='center'
                style={{ margin: '10% 0' }}
            >
                <Grid item container
                    justify="center"
                    md={6} sm={12} xs={12}>
                    <Typography variant='h4' component='span' >
                        {title}
                    </Typography>
                </Grid>
                <Grid item container
                    justify="center"
                    md={6} sm={12} xs={12}>
                    <Typography variant='h6' component='span'>
                        {spec}
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.moreContainer} style={{}}>
                <img style={{
                    height: '100%', width: '100%',
                    display: 'block', bottom: 0, left: 0, right: 0,
                    objectFit: 'cover', transform: 'rotate(-15deg)',
                    borderRadius: '15px'
                }}
                    src={img} />

            </div>
            <div className={classes.moreInfo}>
                <Typography variant='subtitle1' component='p'>
                    - Description:
                </Typography>

                <Typography variant='body2'>
                    {description}
                </Typography>
                <br/>
                <Typography variant='subtitle1'>
                    - Tecnologies: 
                </Typography>
                <Typography variant='body2'>
                    {tecnologies}
                </Typography>
                <br/>
                {
                /*
                <Button color='primary' endIcon={<ArrowForwardIcon/>}>
                    See more
                </Button>
                */
                }

            </div>

        </div>
    )

}

export default WorkCard